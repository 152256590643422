.userName {
  color: var(--color-primary-green);
  cursor: pointer;
}

.icon[data-status='valid'] {
  color: var(--color-primary-green);
}

.icon[data-status='invalid'] {
  color: var(--color-primary-red);
}

.nuevo {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.permissionsHeader {
  min-width: 50px;
  width: 50px;
}

.permissions {
  width: fit-content;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}
