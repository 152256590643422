.card {
  height: calc(100% - 35px);
}
.cardContent {
  display: flex;
  flex-direction: column;
  height: inherit;
  padding: 0;
}

.intro {
  margin: 10px 0 0px;
}

.input {
  margin-bottom: 20px;
}

.innerContainer {
  display: grid;
  padding: 20px 10px 10px;
  margin: 0 auto;
  width: 80%;
}

.permissions {
  margin: 0 auto;
  width: 80%;
  overflow: scroll !important;
  height: 72%;
  margin-top: 15px;
  border: 1px solid #fafafa;
  border-top: none;
}

.componentByPlatform {
  width: 250px;
  max-width: 250px;
}

.cardActions {
  justify-content: right;
}
