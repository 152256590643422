.main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 20px 10px;
}

.main[data-search='inactive'] {
  flex-direction: row-reverse;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.title {
  color: var(--color-dark);
  font-size: 1.15rem;
  letter-spacing: 0.2px;
  font-weight: 300;
  margin: 0;
}
