.main {
  margin-left: var(--width-sidebar);
  padding-top: 50px;
  padding-bottom: 80px;
  width: calc(100% - var(--width-sidebar));
  height: 100vh;
  background-color: white;
  overflow-y: auto;
}

.main:has([data-view='full-screen']) {
  padding-bottom: 0;
}
